<template>
    <div class="mt-3">
        <div v-if="exteriorColors.length > 0" class="form-row">
            <div class="mb-2">
                <strong>
                    <label>{{
                        $t("component.colorFields.exColorLabel")
                    }}</label>
                </strong>
            </div>
            <color-swatch-group
                v-model="selectedExteriorColor"
                :colors="exteriorColors"
            />
            <input
                :value="selectedExteriorColorName"
                type="hidden"
                name="exteriorColor"
            />
        </div>

        <div class="form-row">
            <div class="mb-2">
                <strong>
                    <label>{{
                        $t("component.colorFields.inColorLabel")
                    }}</label>
                </strong>
            </div>
            <color-swatch-group
                v-model="selectedInteriorColor"
                :colors="interiorColors"
            />
            <input
                :value="selectedInteriorColorName"
                type="hidden"
                name="interiorColor"
            />
        </div>
        <div class="d-flex justify-content-center w-100 my-4">
            <b-button
                :disabled="!isValid"
                class="form-btn"
                variant="primary"
                type="submit"
            >
                {{ $t("component.colorFields.continue") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import api from "../../../../api";
import ColorSwatchGroup from "../../../../components/ColorSwatch/components/ColorSwatchGroup";
import _ from "lodash";

export default {
    name: "ColorFields",
    components: { ColorSwatchGroup },

    props: {
        styleId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            exteriorColors: [],
            interiorColors: [
                { name: "Black", hex: "000000" },
                { name: "White", hex: "FFFFFF" },
                { name: "Grey", hex: "D3D3D3" },
                { name: "Brown", hex: "8B4513" },
                { name: "Blue", hex: "0000FF" },
                { name: "Other", hex: "FFFFFF" }
            ],
            selectedExteriorColor: null,
            selectedInteriorColor: null
        };
    },

    computed: {
        isValid() {
            if (
                _.isEmpty(this.exteriorColors) &&
                this.selectedInteriorColor !== null
            ) {
                return true;
            }

            return (
                !_.isEmpty(this.exteriorColors) &&
                this.selectedExteriorColor !== null &&
                this.selectedInteriorColor !== null
            );
        },
        selectedExteriorColorName() {
            return _.get(this.selectedExteriorColor, "name");
        },
        selectedInteriorColorName() {
            return _.get(this.selectedInteriorColor, "name");
        }
    },

    mounted() {
        api.get(`/trade/styles/${this.styleId}/colors`)
            .then(response => {
                this.exteriorColors = response.data;
            })
            .catch(error => {
                console.error(error);
            });
    }
};
</script>
