<template>
    <div class="col-12 p-0 ml-2 mb-0 mt-3">
        <div class="color-list d-flex flex-row flex-wrap">
            <color-swatch
                v-for="color in colors"
                :key="color.name"
                :color="color"
                :selected="isSelected(color)"
                @click="onColorClick(color)"
            />
        </div>
    </div>
</template>

<script>
import ColorSwatch from "../index";
import _ from "lodash";
export default {
    name: "ColorSwatchGroup",
    components: { ColorSwatch },
    props: {
        colors: {
            required: true,
            type: Array
        },
        value: {
            type: Object,
            default: null
        }
    },

    methods: {
        onColorClick(color) {
            if (color.name === _.get(this.value, "name")) {
                this.$emit("input", null);
            } else {
                this.$emit("input", color);
            }
        },

        isSelected(color) {
            return color.name === _.get(this.value, "name");
        }
    }
};
</script>
