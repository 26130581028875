var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 p-0 ml-2 mb-0 mt-3" }, [
    _c(
      "div",
      { staticClass: "color-list d-flex flex-row flex-wrap" },
      _vm._l(_vm.colors, function(color) {
        return _c("color-swatch", {
          key: color.name,
          attrs: { color: color, selected: _vm.isSelected(color) },
          on: {
            click: function($event) {
              return _vm.onColorClick(color)
            }
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }