<template>
    <div>
        <strong>
            <label>{{
                $t("component.exteriorFeatureFields.exteriorLabel")
            }}</label>
        </strong>
        <div class="row ml-0 ml-md-4">
            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <div
                        v-for="(exteriorOption, key) in exteriorOptionsColOne"
                        :key="key"
                        class="form-check mx-2"
                    >
                        <label
                            :for="exteriorOption.index"
                            class="form-check-label"
                        >
                            <input
                                :id="exteriorOption.index"
                                v-model="exteriorOption.checked"
                                :value="exteriorOption.name"
                                class="form-check-input"
                                type="checkbox"
                                aria-required="true"
                                @change="addExteriorFeature(exteriorOption)"
                            />
                            {{ exteriorOption.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <div
                        v-for="(exteriorOption, key) in exteriorOptionsColTwo"
                        :key="key"
                        class="form-check mx-2"
                    >
                        <label
                            :for="exteriorOption.name"
                            class="form-check-label"
                        >
                            <input
                                :id="exteriorOption.name"
                                v-model="exteriorOption.checked"
                                :value="exteriorOption.name"
                                class="form-check-input"
                                type="checkbox"
                                aria-required="true"
                                @change="addExteriorFeature(exteriorOption)"
                            />
                            {{ exteriorOption.name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <input
            v-model="exteriorFeatureFieldValues"
            type="hidden"
            name="exteriorFeatures"
        />

        <div class="d-flex justify-content-center w-100 my-4">
            <ladda-b-btn
                :loading="submitting"
                class="form-btn"
                @click="onSubmit"
            >
                {{ $t("component.exteriorFeatureFields.continue") }}
            </ladda-b-btn>
        </div>
    </div>
</template>

<script>
import LaddaBBtn from "../../../../components/LaddaBBtn/index";
import _ from "lodash";

export default {
    name: "ExteriorFeatureFields",
    components: { LaddaBBtn },

    data() {
        return {
            submitting: false,
            exteriorOptionsColOne: [
                { name: "Parking Sensors", checked: false },
                { name: "Backup Camera", checked: false },
                { name: "Dual Air Bags", checked: false },
                { name: "Side Air Bags", checked: false },
                { name: "Daytime Running Lights", checked: false },
                { name: "Roof Rack", checked: false }
            ],
            exteriorOptionsColTwo: [
                { name: "Towing Package", checked: false },
                { name: "Sunroof", checked: false },
                { name: "Allow Wheels", checked: false },
                { name: "Premium Wheels", checked: false },
                { name: "Fog Lights", checked: false }
            ],
            exteriorFeatureFieldValues: []
        };
    },

    methods: {
        addExteriorFeature(exteriorOption) {
            const array = this.exteriorFeatureFieldValues;
            const optionsFromColOne = _.find(this.exteriorOptionsColOne, {
                name: exteriorOption.name
            });
            const optionsFromColTwo = _.find(this.exteriorOptionsColTwo, {
                name: exteriorOption.name
            });
            const indexOfOptionFromColOne = array.indexOf(optionsFromColOne);
            const indexOfOptionFromColTwo = array.indexOf(optionsFromColTwo);

            if (exteriorOption.checked === true) {
                if (optionsFromColOne) {
                    array.push(exteriorOption.name);
                }

                if (optionsFromColTwo) {
                    array.push(exteriorOption.name);
                }
            } else {
                if (optionsFromColOne) {
                    array.splice(indexOfOptionFromColOne, 1);
                }

                if (optionsFromColTwo) {
                    array.splice(indexOfOptionFromColTwo, 1);
                }
            }
        },
        onSubmit() {
            this.submitting = true;
            document.getElementById("exteriorFeaturesForm").submit();
        }
    }
};
</script>
