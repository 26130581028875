import Vue from "vue";
import _ from "lodash";
import "@/lib/polyfills";
import "../../sass/vendor.scss";
import "../../sass/core.scss";
import "../../sass/internal.scss";

import VehicleFields from "Modules/TradeIn/Form/VehicleFields";
import ColorFields from "Modules/TradeIn/Form/ColorFields";
import DrivetrainFields from "Modules/TradeIn/Form/DrivetrainFields";
import InteriorFeatureFields from "Modules/TradeIn/Form/InteriorFeatureFields";
import ExteriorFeatureFields from "Modules/TradeIn/Form/ExteriorFeatureFields";
import HistoryFields from "Modules/TradeIn/Form/HistoryFields";
import EditContactInfo from "Components/EditContactInfo";
import LaddaBBtn from "Components/LaddaBBtn";
import VueI18n from "vue-i18n";
import Vuex from "vuex";
import pathify from "@/store/pathify";
import user from "@/store/modules/user";
import translated from "@/store/modules/translated";
import location from "@/store/modules/location";
import campaign from "@/store/modules/campaign";
import sessionReset from "@/store/modules/sessionReset";
import used from "@/store/modules/used";
import headerTransitionStore from "@/store/modules/headerTransitionStore";
import CarSaverPlugin from "@/lib/CarSaverPlugin";

const environment = _.get(window, "appConfig.env[0]", "local") || "local";

Vue.use(Vuex);
Vue.use(VueI18n);

const debug = environment !== "production";

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        user,
        translated,
        location,
        sessionReset,
        campaign,
        used,
        headerTransitionStore
    },
    strict: debug
});

Vue.use(CarSaverPlugin);

const defaultMessages = _.get(store, "state.translated.text", {});
const messages = {
    default: defaultMessages,
    en: {},
    es: {}
};
const i18n = new VueI18n({
    locale: "default",
    fallbackLocale: "default",
    messages
});

if (document.getElementById("root") != null) {
    // eslint-disable-next-line no-new
    new Vue({
        el: "#root",

        store,

        i18n,

        components: {
            VehicleFields,
            ColorFields,
            DrivetrainFields,
            InteriorFeatureFields,
            ExteriorFeatureFields,
            HistoryFields,
            EditContactInfo,
            LaddaBBtn
        }
    });
}
