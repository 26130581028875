var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("strong", [
      _c("label", [
        _vm._v(_vm._s(_vm.$t("component.exteriorFeatureFields.exteriorLabel")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row ml-0 ml-md-4" }, [
      _c("div", { staticClass: "col-12 col-md-6" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          _vm._l(_vm.exteriorOptionsColOne, function(exteriorOption, key) {
            return _c("div", { key: key, staticClass: "form-check mx-2" }, [
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: exteriorOption.index }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: exteriorOption.checked,
                        expression: "exteriorOption.checked"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: exteriorOption.index,
                      type: "checkbox",
                      "aria-required": "true"
                    },
                    domProps: {
                      value: exteriorOption.name,
                      checked: Array.isArray(exteriorOption.checked)
                        ? _vm._i(exteriorOption.checked, exteriorOption.name) >
                          -1
                        : exteriorOption.checked
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = exteriorOption.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = exteriorOption.name,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  exteriorOption,
                                  "checked",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  exteriorOption,
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(exteriorOption, "checked", $$c)
                          }
                        },
                        function($event) {
                          return _vm.addExteriorFeature(exteriorOption)
                        }
                      ]
                    }
                  }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(exteriorOption.name) +
                      "\n                    "
                  )
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          _vm._l(_vm.exteriorOptionsColTwo, function(exteriorOption, key) {
            return _c("div", { key: key, staticClass: "form-check mx-2" }, [
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: exteriorOption.name }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: exteriorOption.checked,
                        expression: "exteriorOption.checked"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: exteriorOption.name,
                      type: "checkbox",
                      "aria-required": "true"
                    },
                    domProps: {
                      value: exteriorOption.name,
                      checked: Array.isArray(exteriorOption.checked)
                        ? _vm._i(exteriorOption.checked, exteriorOption.name) >
                          -1
                        : exteriorOption.checked
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = exteriorOption.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = exteriorOption.name,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  exteriorOption,
                                  "checked",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  exteriorOption,
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(exteriorOption, "checked", $$c)
                          }
                        },
                        function($event) {
                          return _vm.addExteriorFeature(exteriorOption)
                        }
                      ]
                    }
                  }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(exteriorOption.name) +
                      "\n                    "
                  )
                ]
              )
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.exteriorFeatureFieldValues,
          expression: "exteriorFeatureFieldValues"
        }
      ],
      attrs: { type: "hidden", name: "exteriorFeatures" },
      domProps: { value: _vm.exteriorFeatureFieldValues },
      on: {
        input: function($event) {
          if ($event.target.composing) return
          _vm.exteriorFeatureFieldValues = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center w-100 my-4" },
      [
        _c(
          "ladda-b-btn",
          {
            staticClass: "form-btn",
            attrs: { loading: _vm.submitting },
            on: { click: _vm.onSubmit }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.exteriorFeatureFields.continue")) +
                "\n        "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }