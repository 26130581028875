<template>
    <div style="position: relative;">
        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="year">
                        {{ $t("component.yearMakeModelSelector.yearLabel") }}
                    </label>
                </strong>
                <select
                    id="year"
                    v-model="selectedYear"
                    class="form-control"
                    aria-required="true"
                    @change="onYearChange"
                >
                    <option :value="null" disabled>
                        {{ $t("component.yearMakeModelSelector.select") }}
                    </option>
                    <option
                        v-for="(year, index) in years"
                        :key="index"
                        :value="year"
                    >
                        {{ year }}
                    </option>
                </select>
            </div>
            <div class="form-group col-12 col-md-6">
                <strong class="d-flex justify-content-between">
                    <label for="make">
                        {{ $t("component.yearMakeModelSelector.makeLabel") }}
                    </label>
                    <div v-if="loadingMakes">
                        <loading-spinner :size="20" />
                    </div>
                </strong>
                <select
                    id="make"
                    v-model="selectedMake"
                    :disabled="isYearUnselected"
                    class="form-control"
                    aria-required="true"
                    @change="onMakeChange"
                >
                    <option :value="null" disabled>
                        {{ $t("component.yearMakeModelSelector.select") }}
                    </option>
                    <option
                        v-for="(make, index) in makes"
                        :key="index"
                        :value="make.id"
                    >
                        {{ make.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong class="d-flex justify-content-between">
                    <label for="model">
                        {{ $t("component.yearMakeModelSelector.modelLabel") }}
                    </label>
                    <div v-if="loadingModels">
                        <loading-spinner :size="20" />
                    </div>
                </strong>
                <select
                    id="model"
                    v-model="selectedModel"
                    :disabled="isMakeUnselected"
                    class="form-control"
                    aria-required="true"
                    @change="onModelChange"
                >
                    <option :value="null" disabled>
                        {{ $t("component.yearMakeModelSelector.select") }}
                    </option>
                    <option
                        v-for="(model, index) in models"
                        :key="index"
                        :value="model.id"
                    >
                        {{ model.name }}
                    </option>
                </select>
            </div>

            <div class="form-group col-12 col-md-6">
                <strong class="d-flex justify-content-between">
                    <label for="style">
                        {{ $t("component.yearMakeModelSelector.trimLabel") }}
                    </label>
                    <div v-if="loadingStyles">
                        <loading-spinner :size="20" />
                    </div>
                </strong>
                <select
                    id="style"
                    v-model="selectedStyle"
                    :disabled="isModelUnselected"
                    class="form-control"
                    aria-required="true"
                    name="styleId"
                    @change="onStyleChange"
                >
                    <option :value="null" disabled>
                        {{ $t("component.yearMakeModelSelector.select") }}
                    </option>
                    <option
                        v-for="(style, index) in styles"
                        :key="index"
                        :value="style.id"
                    >
                        {{ style.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../api";
import _ from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner";

export default {
    name: "YearMakeModelSelector",

    components: {
        LoadingSpinner
    },

    data() {
        return {
            years: null,
            makes: null,
            models: null,
            styles: null,
            selectedYear: null,
            selectedMake: null,
            selectedModel: null,
            selectedStyle: null,
            loadingMakes: false,
            loadingModels: false,
            loadingStyles: false
        };
    },

    computed: {
        isYearUnselected() {
            return _.isEmpty(this.makes);
        },
        isMakeUnselected() {
            return _.isEmpty(this.models);
        },
        isModelUnselected() {
            return _.isEmpty(this.styles);
        }
    },

    mounted() {
        api.get("/trade/years")
            .then(response => {
                this.years = response.data;
            })
            .catch(error => {
                console.error(error);
            });
    },

    methods: {
        onYearChange() {
            this.makes = null;
            this.models = null;
            this.styles = null;
            this.selectedMake = null;
            this.selectedModel = null;
            this.selectedStyle = null;
            this.$emit("valid-change", this.isValid());
            this.populateMakes();
        },
        onMakeChange() {
            this.models = null;
            this.styles = null;
            this.selectedModel = null;
            this.selectedStyle = null;
            this.$emit("valid-change", this.isValid());
            this.populateModels();
        },
        onModelChange() {
            this.selectedStyle = null;
            this.$emit("valid-change", this.isValid());
            this.populateStyles();
        },
        onStyleChange() {
            this.$emit("valid-change", this.isValid());
        },
        populateMakes() {
            this.loadingMakes = true;
            api.get("/trade/makes", { year: this.selectedYear })
                .then(response => {
                    this.makes = response.data;
                    this.loadingMakes = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        populateModels() {
            this.loadingModels = true;
            api.get(`/trade/makes/${this.selectedMake}/models`, {
                year: this.selectedYear
            })
                .then(response => {
                    this.models = response.data;
                    this.loadingModels = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        populateStyles() {
            this.loadingStyles = true;
            api.get(`/trade/models/${this.selectedModel}/styles`, {
                year: this.selectedYear
            })
                .then(response => {
                    this.styles = response.data;
                    this.loadingStyles = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        isValid() {
            return (
                this.selectedYear !== null &&
                this.selectedMake !== null &&
                this.selectedModel !== null &&
                this.selectedStyle !== null
            );
        }
    }
};
</script>
