<template>
    <div>
        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="engineType">
                        {{ $t("component.drivetrainFields.engineTypeLabel") }}
                    </label>
                </strong>
                <select
                    id="engineType"
                    v-model="selectedEngineType"
                    class="form-control"
                    aria-required="true"
                    name="engineType"
                >
                    <option :value="null" disabled>
                        Select
                    </option>
                    <option
                        v-for="(engineType, index) in engineTypes"
                        :key="index"
                        :value="engineType"
                    >
                        {{ engineType }}
                    </option>
                </select>
            </div>

            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="transmission">
                        {{ $t("component.drivetrainFields.transmissionLabel") }}
                    </label>
                </strong>
                <select
                    id="transmission"
                    v-model="selectedTransmission"
                    class="form-control"
                    aria-required="true"
                    name="transmission"
                >
                    <option :value="null" disabled>
                        Select
                    </option>
                    <option
                        v-for="(transmission, index) in transmissions"
                        :key="index"
                        :value="transmission"
                    >
                        {{ transmission }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="fuelType">
                        {{ $t("component.drivetrainFields.fuelTypeLabel") }}
                    </label>
                </strong>
                <select
                    id="fuelType"
                    v-model="selectedFuelType"
                    class="form-control"
                    aria-required="true"
                    name="fuelType"
                >
                    <option :value="null" disabled>
                        Select
                    </option>
                    <option
                        v-for="(fuelType, index) in fuelTypes"
                        :key="index"
                        :value="fuelType"
                    >
                        {{ fuelType }}
                    </option>
                </select>
            </div>

            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="drivetrain">
                        {{ $t("component.drivetrainFields.drivetrainLabel") }}
                    </label>
                </strong>
                <select
                    id="drivetrain"
                    v-model="selectedDrivetrain"
                    class="form-control"
                    aria-required="true"
                    name="drivetrain"
                >
                    <option :value="null" disabled>
                        Select
                    </option>
                    <option
                        v-for="(drivetrain, index) in drivetrains"
                        :key="index"
                        :value="drivetrain"
                    >
                        {{ drivetrain }}
                    </option>
                </select>
            </div>
        </div>

        <div class="d-flex justify-content-center w-100 my-4">
            <ladda-b-btn
                :loading="submitting"
                class="form-btn"
                @click="onSubmit"
            >
                {{ $t("component.drivetrainFields.continue") }}
            </ladda-b-btn>
        </div>
    </div>
</template>

<script>
import LaddaBBtn from "../../../../components/LaddaBBtn/index";

export default {
    name: "DrivetrainFields",
    components: { LaddaBBtn },

    data() {
        return {
            submitting: false,
            engineTypes: ["4 cylinders", "6 cylinders", "8 cylinders", "Other"],
            transmissions: ["Automatic", "Manual"],
            fuelTypes: ["Diesel", "Flex", "Gasoline", "Hybrid"],
            drivetrains: [
                "Four Wheel Drive",
                "Front Wheel Drive",
                "Rear Wheel Drive"
            ],
            selectedEngineType: null,
            selectedTransmission: null,
            selectedFuelType: null,
            selectedDrivetrain: null
        };
    },

    methods: {
        onSubmit() {
            this.submitting = true;
            document.getElementById("drivetrainForm").submit();
        }
    }
};
</script>
