var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c("strong", [
          _c("label", { attrs: { for: "engineType" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.drivetrainFields.engineTypeLabel")) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedEngineType,
                expression: "selectedEngineType"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "engineType",
              "aria-required": "true",
              name: "engineType"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedEngineType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v("\n                    Select\n                ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.engineTypes, function(engineType, index) {
              return _c(
                "option",
                { key: index, domProps: { value: engineType } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(engineType) +
                      "\n                "
                  )
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c("strong", [
          _c("label", { attrs: { for: "transmission" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.drivetrainFields.transmissionLabel")) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedTransmission,
                expression: "selectedTransmission"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "transmission",
              "aria-required": "true",
              name: "transmission"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedTransmission = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v("\n                    Select\n                ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.transmissions, function(transmission, index) {
              return _c(
                "option",
                { key: index, domProps: { value: transmission } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(transmission) +
                      "\n                "
                  )
                ]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c("strong", [
          _c("label", { attrs: { for: "fuelType" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.drivetrainFields.fuelTypeLabel")) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedFuelType,
                expression: "selectedFuelType"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "fuelType",
              "aria-required": "true",
              name: "fuelType"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedFuelType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v("\n                    Select\n                ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.fuelTypes, function(fuelType, index) {
              return _c(
                "option",
                { key: index, domProps: { value: fuelType } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(fuelType) +
                      "\n                "
                  )
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c("strong", [
          _c("label", { attrs: { for: "drivetrain" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.drivetrainFields.drivetrainLabel")) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedDrivetrain,
                expression: "selectedDrivetrain"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "drivetrain",
              "aria-required": "true",
              name: "drivetrain"
            },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedDrivetrain = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v("\n                    Select\n                ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.drivetrains, function(drivetrain, index) {
              return _c(
                "option",
                { key: index, domProps: { value: drivetrain } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(drivetrain) +
                      "\n                "
                  )
                ]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center w-100 my-4" },
      [
        _c(
          "ladda-b-btn",
          {
            staticClass: "form-btn",
            attrs: { loading: _vm.submitting },
            on: { click: _vm.onSubmit }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.drivetrainFields.continue")) +
                "\n        "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }