var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-3" }, [
    _vm.exteriorColors.length > 0
      ? _c(
          "div",
          { staticClass: "form-row" },
          [
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("component.colorFields.exColorLabel")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("color-swatch-group", {
              attrs: { colors: _vm.exteriorColors },
              model: {
                value: _vm.selectedExteriorColor,
                callback: function($$v) {
                  _vm.selectedExteriorColor = $$v
                },
                expression: "selectedExteriorColor"
              }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "exteriorColor" },
              domProps: { value: _vm.selectedExteriorColorName }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row" },
      [
        _c("div", { staticClass: "mb-2" }, [
          _c("strong", [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("component.colorFields.inColorLabel")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("color-swatch-group", {
          attrs: { colors: _vm.interiorColors },
          model: {
            value: _vm.selectedInteriorColor,
            callback: function($$v) {
              _vm.selectedInteriorColor = $$v
            },
            expression: "selectedInteriorColor"
          }
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "interiorColor" },
          domProps: { value: _vm.selectedInteriorColorName }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center w-100 my-4" },
      [
        _c(
          "b-button",
          {
            staticClass: "form-btn",
            attrs: {
              disabled: !_vm.isValid,
              variant: "primary",
              type: "submit"
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.colorFields.continue")) +
                "\n        "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }