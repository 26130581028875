var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("strong", [
      _c("label", { attrs: { for: "year" } }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("component.historyFields.ownedQuestion")) +
            "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c("strong", [
          _c("small", { attrs: { for: "year" } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.historyFields.yearLabel")) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedYearsOwned,
                expression: "selectedYearsOwned"
              }
            ],
            staticClass: "form-control",
            attrs: { id: "year", "aria-required": "true" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedYearsOwned = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.yearsOwned, function(year) {
            return _c(
              "option",
              { key: year.value, domProps: { value: year.value } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(year.label) +
                    "\n                "
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-group col-12 col-md-6",
          attrs: { "aria-required": "true" }
        },
        [
          _c("strong", [
            _c("small", { attrs: { for: "months" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("component.historyFields.monthLabel")) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedMonthsOwned,
                  expression: "selectedMonthsOwned"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "months" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedMonthsOwned = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.monthsOwned, function(month) {
              return _c(
                "option",
                { key: month.value, domProps: { value: month.value } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(month.label) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.computedTimeOwned,
            expression: "computedTimeOwned"
          }
        ],
        attrs: { type: "hidden", name: "ownedLength" },
        domProps: { value: _vm.computedTimeOwned },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.computedTimeOwned = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        {
          staticClass: "form-group col-12",
          attrs: { "aria-required": "true" }
        },
        [
          _c("strong", [
            _c("label", { attrs: { for: "purchaseType" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.historyFields.purchaseTypeQuestion")
                  ) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedPurchaseType,
                  expression: "selectedPurchaseType"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "purchaseType", name: "purchaseType" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedPurchaseType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { disabled: "" }, domProps: { value: null } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.historyFields.select")) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.purchasedType, function(type) {
                return _c("option", { key: type, domProps: { value: type } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(type) +
                      "\n                "
                  )
                ])
              })
            ],
            2
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-12 col-md-6" }, [
        _c(
          "strong",
          [
            _c("label", { attrs: { for: "currentPayment" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.historyFields.monthlyPaymentLabel")
                  ) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("input-mask", {
              staticClass: "form-control",
              attrs: {
                id: "maskedCurrentPayment",
                mask: ["$#", "$##", "$###!", "$#,###"],
                type: "text",
                placeholder: "$"
              },
              model: {
                value: _vm.maskedCurrentPayment,
                callback: function($$v) {
                  _vm.maskedCurrentPayment = $$v
                },
                expression: "maskedCurrentPayment"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedCurrentPayment,
                  expression: "selectedCurrentPayment"
                }
              ],
              attrs: {
                id: "currentPayment",
                type: "hidden",
                name: "currentPayment"
              },
              domProps: { value: _vm.selectedCurrentPayment },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.selectedCurrentPayment = $event.target.value
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-group col-12 col-md-6",
          attrs: { "aria-required": "true" }
        },
        [
          _c("strong", [
            _c("label", { attrs: { for: "remainingPayments" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t("component.historyFields.remainingPaymentsLabel")
                  ) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedRemainingPayments,
                  expression: "selectedRemainingPayments"
                }
              ],
              staticClass: "form-control",
              attrs: { id: "remainingPayments", name: "remainingPayments" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedRemainingPayments = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.remainingYears, function(remainingYear) {
              return _c(
                "option",
                {
                  key: remainingYear.value,
                  domProps: { value: remainingYear.value }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(remainingYear.label) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-group col-md-12" },
        [
          _c("strong", [
            _c("label", { attrs: { for: "vin" } }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("component.historyFields.vinLabel")) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("input-mask", {
            staticClass: "form-control",
            staticStyle: { "text-transform": "uppercase" },
            attrs: {
              id: "vin",
              mask: "XXXXXXXXXXXXXXXXX",
              type: "text",
              name: "vin"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center w-100 my-4" },
      [
        _c(
          "ladda-b-btn",
          {
            staticClass: "form-btn",
            attrs: { loading: _vm.submitting },
            on: { click: _vm.onSubmit }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.historyFields.continue")) +
                "\n        "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }