<template>
    <div>
        <strong>
            <label>{{
                $t("component.interiorFeatureFields.interiorLabel")
            }}</label>
        </strong>
        <div class="row ml-0 ml-md-4">
            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <div
                        v-for="(interiorOption, key) in interiorOptionsColOne"
                        :key="key"
                        class="form-check mx-2"
                    >
                        <label
                            :for="interiorOption.index"
                            class="form-check-label"
                        >
                            <input
                                :id="interiorOption.index"
                                v-model="interiorOption.checked"
                                :value="interiorOption.name"
                                class="form-check-input"
                                type="checkbox"
                                aria-required="true"
                                @change="addInteriorFeature(interiorOption)"
                            />
                            {{ interiorOption.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="d-flex flex-column">
                    <div
                        v-for="(interiorOption, key) in interiorOptionsColTwo"
                        :key="key"
                        class="form-check mx-2"
                    >
                        <label
                            :for="interiorOption.name"
                            class="form-check-label"
                        >
                            <input
                                :id="interiorOption.name"
                                v-model="interiorOption.checked"
                                :value="interiorOption.name"
                                class="form-check-input"
                                type="checkbox"
                                aria-required="true"
                                @change="addInteriorFeature(interiorOption)"
                            />
                            {{ interiorOption.name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <input
            v-model="interiorFeatureFieldValues"
            type="hidden"
            name="interiorFeatures"
        />

        <div class="d-flex justify-content-center w-100 my-4">
            <ladda-b-btn
                :loading="submitting"
                class="form-btn"
                @click="onSubmit"
            >
                {{ $t("component.interiorFeatureFields.continue") }}
            </ladda-b-btn>
        </div>
    </div>
</template>

<script>
import LaddaBBtn from "../../../../components/LaddaBBtn/index";
import _ from "lodash";

export default {
    name: "InteriorFeatureFields",
    components: { LaddaBBtn },

    data() {
        return {
            submitting: false,
            interiorOptionsColOne: [
                { name: "AM/FM Stereo", checked: false },
                { name: "Premium Sound", checked: false },
                { name: "MP3", checked: false },
                { name: "SiriusXM Radio", checked: false },
                { name: "Navigation System", checked: false },
                { name: "DVD System", checked: false },
                { name: "Keyless Entry", checked: false },
                { name: "Keyless Start", checked: false }
            ],
            interiorOptionsColTwo: [
                { name: "Power Windows", checked: false },
                { name: "Power Door Locks", checked: false },
                { name: "Cruise Control", checked: false },
                { name: "Heated Seats", checked: false },
                { name: "Power Seat", checked: false },
                { name: "Dual Power Seats", checked: false },
                { name: "Leather", checked: false },
                { name: "Third Row Seat", checked: false }
            ],
            interiorFeatureFieldValues: []
        };
    },

    methods: {
        addInteriorFeature(interiorOption) {
            const array = this.interiorFeatureFieldValues;
            const optionsFromColOne = _.find(this.interiorOptionsColOne, {
                name: interiorOption.name
            });
            const optionsFromColTwo = _.find(this.interiorOptionsColTwo, {
                name: interiorOption.name
            });
            const indexOfOptionFromColOne = array.indexOf(optionsFromColOne);
            const indexOfOptionFromColTwo = array.indexOf(optionsFromColTwo);

            if (interiorOption.checked === true) {
                if (optionsFromColOne) {
                    array.push(interiorOption.name);
                }

                if (optionsFromColTwo) {
                    array.push(interiorOption.name);
                }
            } else {
                if (optionsFromColOne) {
                    array.splice(indexOfOptionFromColOne, 1);
                }

                if (optionsFromColTwo) {
                    array.splice(indexOfOptionFromColTwo, 1);
                }
            }
        },
        onSubmit() {
            this.submitting = true;
            document.getElementById("interiorFeaturesForm").submit();
        }
    }
};
</script>
