var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("year-make-model-selector", {
        on: { "valid-change": _vm.onYMMChange }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-group col-md-12 col-md-6" },
          [
            _c("strong", [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.$t("component.yearMakeModelSelector.mileageLabel"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("input-mask", {
              staticClass: "form-control",
              attrs: {
                id: "maskedMileage",
                mask: ["#", "##", "###!", "#,###", "##,###", "###,###"],
                type: "text",
                placeholder: "Mileage*"
              },
              nativeOn: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.onEnter.apply(null, arguments)
                }
              },
              model: {
                value: _vm.maskedMileage,
                callback: function($$v) {
                  _vm.maskedMileage = $$v
                },
                expression: "maskedMileage"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mileage,
                  expression: "mileage"
                }
              ],
              attrs: { id: "mileage", type: "hidden", name: "mileage" },
              domProps: { value: _vm.mileage },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.mileage = $event.target.value
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center w-100 my-4" },
        [
          _c(
            "ladda-b-btn",
            {
              staticClass: "form-btn",
              attrs: { loading: _vm.submitting, disabled: _vm.isFormInvalid },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("\n            Continue\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }