<template>
    <div>
        <strong>
            <label for="year">
                {{ $t("component.historyFields.ownedQuestion") }}
            </label>
        </strong>
        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong>
                    <small for="year">
                        {{ $t("component.historyFields.yearLabel") }}
                    </small>
                </strong>
                <select
                    id="year"
                    v-model="selectedYearsOwned"
                    class="form-control"
                    aria-required="true"
                >
                    <option
                        v-for="year in yearsOwned"
                        :key="year.value"
                        :value="year.value"
                    >
                        {{ year.label }}
                    </option>
                </select>
            </div>

            <div class="form-group col-12 col-md-6" aria-required="true">
                <strong>
                    <small for="months">
                        {{ $t("component.historyFields.monthLabel") }}
                    </small>
                </strong>
                <select
                    id="months"
                    v-model="selectedMonthsOwned"
                    class="form-control"
                >
                    <option
                        v-for="month in monthsOwned"
                        :key="month.value"
                        :value="month.value"
                    >
                        {{ month.label }}
                    </option>
                </select>
            </div>
            <input
                v-model="computedTimeOwned"
                type="hidden"
                name="ownedLength"
            />
        </div>

        <div class="form-row">
            <div class="form-group col-12" aria-required="true">
                <strong>
                    <label for="purchaseType">
                        {{ $t("component.historyFields.purchaseTypeQuestion") }}
                    </label>
                </strong>
                <select
                    id="purchaseType"
                    v-model="selectedPurchaseType"
                    class="form-control"
                    name="purchaseType"
                >
                    <option :value="null" disabled>
                        {{ $t("component.historyFields.select") }}
                    </option>
                    <option
                        v-for="type in purchasedType"
                        :key="type"
                        :value="type"
                    >
                        {{ type }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-12 col-md-6">
                <strong>
                    <label for="currentPayment">
                        {{ $t("component.historyFields.monthlyPaymentLabel") }}
                    </label>
                    <input-mask
                        id="maskedCurrentPayment"
                        v-model="maskedCurrentPayment"
                        :mask="['$#', '$##', '$###!', '$#,###']"
                        type="text"
                        class="form-control"
                        placeholder="$"
                    />
                    <input
                        id="currentPayment"
                        v-model="selectedCurrentPayment"
                        type="hidden"
                        name="currentPayment"
                    />
                </strong>
            </div>
            <div class="form-group col-12 col-md-6" aria-required="true">
                <strong>
                    <label for="remainingPayments">
                        {{
                            $t("component.historyFields.remainingPaymentsLabel")
                        }}
                    </label>
                </strong>
                <select
                    id="remainingPayments"
                    v-model="selectedRemainingPayments"
                    class="form-control"
                    name="remainingPayments"
                >
                    <option
                        v-for="remainingYear in remainingYears"
                        :key="remainingYear.value"
                        :value="remainingYear.value"
                    >
                        {{ remainingYear.label }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <strong>
                    <label for="vin">
                        {{ $t("component.historyFields.vinLabel") }}
                    </label>
                </strong>
                <input-mask
                    id="vin"
                    :mask="'XXXXXXXXXXXXXXXXX'"
                    type="text"
                    class="form-control"
                    name="vin"
                    style="text-transform:uppercase"
                />
            </div>
        </div>

        <div class="d-flex justify-content-center w-100 my-4">
            <ladda-b-btn
                :loading="submitting"
                class="form-btn"
                @click="onSubmit"
            >
                {{ $t("component.historyFields.continue") }}
            </ladda-b-btn>
        </div>
    </div>
</template>

<script>
import LaddaBBtn from "../../../../components/LaddaBBtn/index";
import { mask, TheMask as InputMask } from "vue-the-mask";
import _ from "lodash";

export default {
    name: "HistoryFields",
    components: { LaddaBBtn, InputMask },

    directives: { mask },

    data() {
        return {
            yearsOwned: [
                { label: "0 years", value: 0 },
                { label: "1 year", value: 1 },
                { label: "2 years", value: 2 },
                { label: "3 years", value: 3 },
                { label: "4 years", value: 4 },
                { label: "5 years", value: 5 },
                { label: "6 years", value: 6 },
                { label: "7 years", value: 7 },
                { label: "8 years", value: 8 },
                { label: "9 years", value: 9 },
                { label: "10 years", value: 10 },
                { label: "10 + years", value: 11 }
            ],
            monthsOwned: [
                { label: "0 months", value: 0 },
                { label: "1 month", value: 1 },
                { label: "2 months", value: 2 },
                { label: "3 months", value: 3 },
                { label: "4 months", value: 4 },
                { label: "5 months", value: 5 },
                { label: "6 months", value: 6 },
                { label: "7 months", value: 7 },
                { label: "8 months", value: 8 },
                { label: "9 months", value: 9 },
                { label: "10 months", value: 10 },
                { label: "11 months", value: 11 }
            ],
            purchasedType: ["Cash", "Lease", "Loan"],
            remainingYears: [
                { label: "0 years", value: 0 },
                { label: "1 year", value: 1 },
                { label: "2 years", value: 2 },
                { label: "3 years", value: 3 },
                { label: "4 years", value: 4 },
                { label: "5 years", value: 5 },
                { label: "6 years", value: 6 },
                { label: "7+ years", value: 7 }
            ],
            selectedYearsOwned: 0,
            selectedMonthsOwned: 0,
            selectedPurchaseType: null,
            selectedCurrentPayment: null,
            selectedRemainingPayments: 0,
            submitting: false,
            maskedCurrentPayment: null
        };
    },

    computed: {
        computedTimeOwned: function() {
            return this.selectedYearsOwned * 12 + this.selectedMonthsOwned;
        }
    },

    watch: {
        maskedCurrentPayment() {
            if (!_.isNil(this.maskedCurrentPayment)) {
                this.selectedCurrentPayment = this.maskedCurrentPayment;
                this.selectedCurrentPayment.replace(/\D/g, "");
            }
        }
    },

    methods: {
        onSubmit() {
            this.submitting = true;
            document.getElementById("historyForm").submit();
        }
    }
};
</script>
