<template>
    <div>
        <year-make-model-selector @valid-change="onYMMChange" />
        <div class="form-row">
            <div class="form-group col-md-12 col-md-6">
                <strong>
                    <label>{{
                        $t("component.yearMakeModelSelector.mileageLabel")
                    }}</label>
                </strong>
                <input-mask
                    id="maskedMileage"
                    v-model="maskedMileage"
                    :mask="['#', '##', '###!', '#,###', '##,###', '###,###']"
                    type="text"
                    class="form-control"
                    placeholder="Mileage*"
                    @keydown.enter.native.prevent="onEnter"
                />
                <input
                    id="mileage"
                    v-model="mileage"
                    type="hidden"
                    name="mileage"
                />
            </div>
        </div>
        <div class="d-flex justify-content-center w-100 my-4">
            <ladda-b-btn
                :loading="submitting"
                :disabled="isFormInvalid"
                class="form-btn"
                @click="onSubmit"
            >
                Continue
            </ladda-b-btn>
        </div>
    </div>
</template>

<script>
import YearMakeModelSelector from "Components/YearMakeModelSelector";
import LaddaBBtn from "Components/LaddaBBtn";
import { TheMask as InputMask } from "vue-the-mask";
import _ from "lodash";

export default {
    name: "VehicleFields",
    components: { LaddaBBtn, YearMakeModelSelector, InputMask },

    data() {
        return {
            maskedMileage: null,
            mileage: null,
            submitting: false,
            ymmValid: false
        };
    },

    computed: {
        isFormInvalid() {
            return !this.mileage || !this.ymmValid;
        }
    },

    watch: {
        maskedMileage() {
            if (!_.isNil(this.maskedMileage)) {
                this.mileage = this.maskedMileage;
                this.mileage.replace(/\D/g, "");
            }
        }
    },

    methods: {
        onSubmit() {
            this.submitting = true;
            document.getElementById("vehicleForm").submit();
        },
        onYMMChange(valid) {
            this.ymmValid = valid;
        },
        onEnter() {
            if (!this.isFormInvalid) {
                this.onSubmit();
            }

            return false;
        }
    }
};
</script>
